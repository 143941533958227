.wrapper {
  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }
}

.image {
  & figcaption {
    @apply mt-4 font-sans text-sm text-center;
  }

  & img {
    @apply w-full;
  }

  &:global(.size-thumbnail) {
    @apply max-w-[200px];
  }

  &:global(.size-full) {
    @apply max-w-8xl box-content mx-auto lg:px-8;
  }

  &:global(.align-center) {
    @apply mx-auto;
  }

  &:global(.force-square) {
    @apply aspect-1 w-full relative;
  }
}

@media print {
  .image {
    @apply mx-auto w-1/2;

    &:global(.size-full) {
      @apply w-full;
    }
  }
}
